import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import * as worldService from "./services/worldService";
import * as sessionService from "./services/sessionService";

import "./index.css";
import "./assets/fonts.css";

import App from "./App";

sessionService.resolveSession().then(session => {
  sessionService.setHttpSession(session);
  const world =
    process.env.NODE_ENV === "production"
      ? window.location.host
      : "denverzoo.dev.sparkd.com";

  worldService.fetchWorld(world).then(
    result => {
      if (result.status === 200) {
        const { world } = result.data.data;

        var myDynamicManifest = {
          name: world.name + " - SPARKd",
          short_name: world.name + " - SPARKd",
          description: world.headerTitle || "",
          start_url: window.location.host,
          background_color: world.worldBackgroundColor,
          icons: [
            {
              src: "favicon.ico",
              sizes: "64x64 32x32 24x24 16x16",
              type: "image/x-icon"
            },
            {
              src: "logo192.png",
              type: "image/png",
              sizes: "192x192"
            },
            {
              src: "logo512.png",
              type: "image/png",
              sizes: "512x512"
            }
          ],
          display: "standalone"
        };
        const stringManifest = JSON.stringify(myDynamicManifest);
        const blob = new Blob([stringManifest], { type: "application/json" });
        const manifestURL = URL.createObjectURL(blob);
        document
          .querySelector("#manifest-file")
          .setAttribute("href", manifestURL);

        return ReactDOM.render(
          <BrowserRouter>
            <App world={world} />
          </BrowserRouter>,
          document.getElementById("root")
        );
      }

      return null;
    },
    err => {
      if (err.response.status === 404) {
        return ReactDOM.render(
          <div>SPARKd</div>,
          document.getElementById("root")
        );
      }
    }
  );
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();

import React from "react";
import styled from "styled-components";
import Card from "../Card";

const StyledFullWidthCard = styled.div`
  & > .card {
    min-width: 335px;
    min-height: 337px;
    max-height: 337px;
  }

  & > .card .card-title {
    font-size: 1.25rem;
    line-height: 2rem;
  }

  & > .card .card-image {
    max-height: 213px;
    min-height: 213px;
    height: 213px;
    min-width: 100%;
  }
`;

const FullWidthCard = props => (
  <StyledFullWidthCard>
    <Card {...props} />
  </StyledFullWidthCard>
);

FullWidthCard.defaultProps = {
  className: "full-width-card"
};

export default FullWidthCard;

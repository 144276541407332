import React from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { fetchChallenge } from "../../../services/challengeService";
import { getUniqueCookie } from "../../../services/cookiesService";

import loaderImage from "../../../assets/images/hourglass-small-transparent.gif";

const StyledLoaderWrapper = styled.div`
  background-color: white;
  position: absolute;
  width: 100%;
  height: 100%;
  margin: 0px auto;
  text-align: center;
  padding: 30px 0px;
  top: 0;
  left: 0;
  display: block;
`;

const StyledLoaderImage = styled.img`
  width: 25%;
  max-width: 25%;
  top: 12%;
  height: auto;
  position: relative;

  /* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

  @media (min-width: 1281px) {
    width: 25%;
    max-width: 25%;
    top: 18%;
  }

  /* 
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/

  @media (min-width: 1025px) and (max-width: 1280px) {
    width: 25%;
    max-width: 25%;
    top: 18%;
  }

  /* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

  @media (min-width: 768px) and (max-width: 1024px) {
    width: 50%;
    max-width: 50%;
    top: 22%;
  }

  /* 
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    width: 50%;
    max-width: 50%;
    top: 25%;
  }

  /* 
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

  @media (min-width: 481px) and (max-width: 767px) {
    //CSS
  }

  /* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

  @media (min-width: 320px) and (max-width: 480px) {
    width: 50%;
    max-width: 50%;
    top: 25%;
  }
`;

const StyledDrimifyContainer = styled.div`
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  margin-bottom: 30px;

  /* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

  @media (min-width: 1281px) {
    height: ${props => !props.loaded && "666px"};
  }

  /* 
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/

  @media (min-width: 1025px) and (max-width: 1280px) {
    height: ${props => !props.loaded && "666px"};
  }

  /* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

  @media (min-width: 768px) and (max-width: 1024px) {
    height: ${props => !props.loaded && "666px"};
  }

  /* 
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    //CSS
  }

  /* 
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

  @media (min-width: 481px) and (max-width: 767px) {
    //CSS
  }

  /* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

  @media (min-width: 320px) and (max-width: 480px) {
    height: ${props => !props.loaded && "400px"};
  }
`;

class Game extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false
    };
    this.drimifyContainerRef = null;
  }

  componentDidMount() {
    if (this.props.challenge) {
      this.loadDrimify(this.props.challenge);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.match.params.challenge !== prevProps.match.params.challenge
    ) {
      this.setState({ loaded: false });
      if (this.drimifyContainerRef) {
        this.drimifyContainerRef.innerHTML = "";
      }
      this.loadChallenge(this.props.challenge.id);
    }
  }

  loadChallenge = id => {
    fetchChallenge(this.props.world.domain, id).then(result => {
      const { challenge } = result.data.data;
      this.loadDrimify(challenge);
    });
  };

  loadDrimify = challenge => {
    const self = this;
    setTimeout(() => {
      var drimifyWidget = new window.Drimify.Widget({
        autofocus: true,
        height: "100%",
        element: "drimify-container",
        engine: challenge.embed + `&custom_drimid=${getUniqueCookie()}`,
        style: "border:none"
      });

      drimifyWidget.onReady = function() {
        self.setState({
          loaded: true
        });
      };

      drimifyWidget.load();
    }, 200);
  };

  render() {
    return (
      <div style={{ position: "relative" }}>
        <StyledDrimifyContainer
          id="drimify-container"
          style={{
            display: "block",
            position: "relative",
            top: "0",
            left: "0",
            lineHeight: "0",
            frameBorder: "0",
            visibility: this.state.loaded ? "visible" : "hidden"
          }}
          loaded={this.state.loaded}
          ref={ref => (this.drimifyContainerRef = ref)}
        />
        {!this.state.loaded && (
          <StyledLoaderWrapper>
            <StyledLoaderImage src={loaderImage} alt="" className="img-fluid" />
          </StyledLoaderWrapper>
        )}
      </div>
    );
  }
}

export default withRouter(Game);

import http from "./http";
import { sprintf } from "sprintf-js";

import { CHALLENGES_VIEW_API_ENDPOINT } from "./endpoints";

export function fetchChallenge(worldId, id) {
  return http.get(
    sprintf(CHALLENGES_VIEW_API_ENDPOINT, {
      worldId: worldId,
      challengeId: id
    })
  );
}

import React from "react";
import styled from "styled-components";
import SourceAwareLink from "../SourceAwareLink";

import backIcon from "../../assets/icons/back.png";
import AppContext from "../../AppContext";
import IconFavorites from "../Icons/IconFavorites";

const StyledNavigationLogo = styled.img`
  display: block;

  max-height: 64px;
`;

const StyledNavigation = styled.div`
  padding: 20px;
  background-color: #fff;

  &:before {
    content: "";
    width: 100%;
    height: ${props => `${props.height}px`};
    background-color: #fff;
  }
`;

const StyledBackButton = styled.img`
  display: inline-block;
  margin-right: 20px;
  float: left;
  width: 24px;
  margin-top: 25%;
`;

const StyledNavItem = styled.span`
  display: inline-block;
  width: 24px;
  position: relative;
  float: right;
  margin-top: 25px;
`;

class Navigation extends React.Component {
  render() {
    return (
      <StyledNavigation height={this.props.height}>
        <SourceAwareLink className="d-inline-block" to={this.props.backTo}>
          <StyledBackButton src={backIcon} className="align-middle" />
          <StyledNavigationLogo
            src={this.props.world.theme.headerLogo}
            alt="title"
          />
        </SourceAwareLink>

        {this.props.challenge && (
          <StyledNavItem>
            <IconFavorites
              favorite={this.props.challenge.isFavorite}
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                this.props.onToggleFavoriteChallenge(
                  this.props.challenge.id,
                  this.props.challenge.isFavorite
                );
              }}
            />
          </StyledNavItem>
        )}
      </StyledNavigation>
    );
  }
}

Navigation.contextType = AppContext;

export default Navigation;

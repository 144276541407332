const FAVORITES_UPDATER = "favorites";
const NEW_UPDATER = "new";
const RECENT_UPDATER = "recent";
const CATEGORY_LIST_UPDATER = "category-list";
const CATEGORY_GRID_UPDATER = "category-grid";
const CHALLENGE_CATEGORY_LIST_UPDATER = "challenge-category-list";
const OTHER_CATEGORIES_UPDATER = "other-categories";

const RECENT_APPENDER = `${RECENT_UPDATER}-append`;

const FAVORITE_APPENDER = `${FAVORITES_UPDATER}-append`;
const FAVORITE_REMOVER = `${FAVORITES_UPDATER}-remove`;

const layoutUpdaters = {
  [FAVORITES_UPDATER]: (data, id, updateProps) =>
    favoritesListUpdater(data, id, updateProps),
  [NEW_UPDATER]: (data, id, updateProps) =>
    newChallengesListUpdater(data, id, updateProps),
  [RECENT_UPDATER]: (data, id, updateProps) =>
    recentListUpdater(data, id, updateProps),
  [CATEGORY_LIST_UPDATER]: (data, id, updateProps) =>
    categoriesListUpdater(data, id, updateProps),
  [CATEGORY_GRID_UPDATER]: (data, id, updateProps) =>
    categoryChallengesListUpdater(data, id, updateProps),
  [CHALLENGE_CATEGORY_LIST_UPDATER]: (data, id, updateProps) =>
    challengeCategoryListUpdater(data, id, updateProps),
  [OTHER_CATEGORIES_UPDATER]: (data, id, updateProps) =>
    otherCategoriesUpdater(data, id, updateProps),
  [RECENT_APPENDER]: (data, appendData) => recentListAppender(data, appendData),
  [FAVORITE_APPENDER]: (data, appendData) =>
    favoritesListAppender(data, appendData),
  [FAVORITE_REMOVER]: (data, id) => favoritesListRemover(data, id)
};

const favoritesListUpdater = (data, id, updateProps) => {
  return data.map(item => {
    if (item.id !== id) {
      return {
        ...item
      };
    }

    return {
      ...item,
      ...updateProps
    };
  });
};

const newChallengesListUpdater = (data, id, updateProps) => {
  return favoritesListUpdater(data, id, updateProps);
};

const recentListUpdater = (data, id, updateProps) => {
  return favoritesListUpdater(data, id, updateProps);
};

const categoriesListUpdater = (data, id, updateProps) => {
  return data.map(item => {
    return {
      ...item,
      challenges: item.challenges.map(challenge => {
        if (challenge.id !== id) {
          return { ...challenge };
        }

        return {
          ...challenge,
          ...updateProps
        };
      })
    };
  });
};

const categoryChallengesListUpdater = (data, id, updateProps) => {
  return categoriesListUpdater(data, id, updateProps);
};

const challengeCategoryListUpdater = (data, id, updateProps) => {
  return data;
};

const otherCategoriesUpdater = (data, id, updateProps) => {
  return data;
};

const recentListAppender = (data, appendData) => {
  if (!appendData || !appendData.id) return data;
  const filteredData = data.filter(item => item.id !== appendData.id);
  return [appendData].concat(filteredData);
};

const favoritesListAppender = (data, appendData) => {
  if (!appendData || !appendData.id) return data;
  const filteredData = data.filter(item => item.id !== appendData.id);

  return [appendData].concat(filteredData);
};

const favoritesListRemover = (data, id) => {
  const filteredData = data.filter(item => item.id !== id);
  return filteredData;
};

export const updateLayout = (layout, data, id, updateProps) => {
  return layoutUpdaters[layout]
    ? layoutUpdaters[layout](data, id, updateProps)
    : data;
};

export const appendToLayout = (layout, data, appendData) => {
  return layoutUpdaters[`${layout}-append`]
    ? layoutUpdaters[`${layout}-append`](data, appendData)
    : data;
};

export const removeFromLayout = (layout, data, id) => {
  return layoutUpdaters[`${layout}-remove`]
    ? layoutUpdaters[`${layout}-remove`](data, id)
    : data;
};

import React from "react";
import styled from "styled-components";

const StyledIcon = styled.span`
  display: inline-block;
  position: relative;
  margin: 0;
  padding: 0;
  font-size: 25px;
  top: -1px;
  vertical-align: middle;
`;

const Icon = props => (
  <StyledIcon
    {...props}
    className={`icon icon-${props.icon} ${props.className}`}
  />
);
export default Icon;

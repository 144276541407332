import React from "react";
import styled from "styled-components";
import Card from "../Card";

const StyledLargeCard = styled.div`
  & > .card {
    max-width: 310px;
    min-height: 318px;
    max-height: 318px;
  }

  & > .card .card-title {
    font-size: 1.25rem;
    line-height: 2rem;
  }

  & > .card .card-image {
    max-height: 194px;
    min-height: 194px;
    height: 194px;
    min-width: 100%;
  }
`;

const LargeCard = props => (
  <StyledLargeCard>
    <Card {...props} />
  </StyledLargeCard>
);

LargeCard.defaultProps = {
  className: "large-card"
};

export default LargeCard;

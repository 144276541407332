import React from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";

const StyledModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.85);
  z-index: 9999;
  overflow-y: none;
  padding: 30px 0;
`;

const StyledModal = styled.div`

  display: inline;
  position: relative;
  min-height: 70%;
  height: 100%;

  & > .modal-dialog {
    width: 95%;
    max-width: 95%;
  }

  & > .modal-dialog > .modal-content {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    background-color: transparent;
    border: 0;
   
  }

  & > .modal-dialog > .modal-content .modal-header {
    padding: 30px;
    padding-bottom: 15px;
    
  }

  & > .modal-dialog > .modal-content .modal-header .modal-title {
    font-size: 20px;
    font-weight: 600;
    line-height: 1.4;
   
  }

  & > .modal-dialog > .modal-content .modal-header .modal-subTitle {
    font-size: 16px;
    line-height: 1.5l
    margin-bottom: 0;
  }

  & > .modal-dialog > .modal-content .modal-header .close {
    margin: -3rem -1.5rem -1rem auto;
    outline: 0;
  }

  & > .modal-dialog > .modal-content > .modal-body {
  padding: 0;
  text-align: center;
  }

  & > .modal-dialog > .modal-content > .modal-footer {
    padding: 30px;

      @media (max-width: 375px) {
        padding: 30px 8px;
      }
  }

  & > .modal-dialog > .modal-content > .modal-footer .btn:last-child {
    margin-right: 0;
  }
  & > .modal-dialog > .modal-content > .modal-footer .ghost-button {
    margin-right: auto !important;
  }
`;

const modalRoot = document.getElementById("modal-root");

class Modal extends React.Component {
  renderModal = () => {
    return (
      <StyledModalWrapper>
        <StyledModal className="modal" tabindex="-1" role="dialog">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-body">{this.props.children}</div>
            </div>
          </div>
        </StyledModal>
      </StyledModalWrapper>
    );
  };

  render() {
    const Modal = this.renderModal();
    return ReactDOM.createPortal(Modal, modalRoot);
  }
}

export default Modal;

import React from "react";
import styled from "styled-components";

import IconFavorites from "../../Icons/IconFavorites";
import Skeleton from "react-loading-skeleton";

const StyledCard = styled.div`
  display: inline-block;
  position: relative;
  padding: ${props => props.theme.cardPadding && props.theme.cardPadding};
  background-color: ${props =>
    props.theme.cardBackgroundColor && props.theme.cardBackgroundColor};
  border: ${props =>
    props.theme.cardBorderColor && `1px solid ${props.theme.cardBorderColor}`};

  border-radius: ${props =>
    props.theme.cardBorderRadius && props.theme.cardBorderRadius};
  max-width: 215px;
  box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.07);
  margin-bottom: 30px;
  min-height: 196px;
`;

const StyledThumbnail = styled.img`
  margin: 0;
  margin-bottom: 15px;
  border-top-left-radius: ${props =>
    parseInt(props.theme.cardBorderRadius, 10) > 0
      ? props.theme.cardBorderRadius
      : "0px"};
  border-top-right-radius: ${props =>
    parseInt(props.theme.cardBorderRadius, 10) > 0
      ? props.theme.cardBorderRadius
      : "0px"};
`;

const StyledNew = styled.span`
  background-color: ${props =>
    props.theme.cardIndicatorBackgroundColor &&
    props.theme.cardIndicatorBackgroundColor};

  color: ${props =>
    props.theme.cardIndicatorTextColor && props.theme.cardIndicatorTextColor};
  margin-bottom: 15px;
  font-size: 0.7rem;
  font-weight: 900;
  padding: 5px 10px;
  display: inline-block;
  border-radius: 5px;
  text-transform: uppercase;
  position: absolute;
  left: 10px;
  bottom: 10px;
`;

const StyledTitle = styled.span`
  display: block;
  font-size: 1rem;
  font-weight: 700;
  color: ${props => props.theme.cardTitleColor && props.theme.cardTitleColor};

  width: 100%;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;

  position: relative;
  padding-right: 35px;
`;

const StyledTitleWrapper = styled.div`
  text-align: left;
  padding-left: ${props =>
    !props.theme.cardPadding || parseInt(props.theme.cardPadding, 10) === 0
      ? "15px"
      : "0px"};

  padding-right: ${props =>
    !props.theme.cardPadding || parseInt(props.theme.cardPadding, 10) === 0
      ? "15px"
      : "0px"};

  padding-bottom: ${props =>
    !props.theme.cardPadding || parseInt(props.theme.cardPadding, 10) === 0
      ? "15px"
      : "0px"};
`;

const Card = ({
  title,
  thumb,
  isNew,
  newTitle,
  favorite,
  id,
  canBeFavorite,
  ...rest
}) => (
  <StyledCard className={`card ${rest.className}`}>
    <div className="d-block">
      <div className="position-relative">
        {thumb ? (
          <StyledThumbnail
            src={thumb}
            className="img-fluid card-image"
            alt=""
          />
        ) : (
          <Skeleton className="img-fluid card-image" />
        )}
        {isNew && newTitle && (
          <StyledNew className="card-is-new">{newTitle}</StyledNew>
        )}
      </div>
      <StyledTitleWrapper>
        <StyledTitle className="card-title">
          {title ? (
            <span>
              {title}{" "}
              {canBeFavorite && (
                <IconFavorites
                  favorite={favorite}
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    rest.toggleFavoriteChallenge(id, favorite);
                  }}
                />
              )}
            </span>
          ) : (
            <Skeleton />
          )}
        </StyledTitle>
      </StyledTitleWrapper>
    </div>
  </StyledCard>
);

export default Card;

import React from "react";
import styled from "styled-components";

import appStoreImg from "./download-app-store.png";
import playStoreImg from "./download-google-play.png";

const StyledDownloadMobileApp = styled.div`
  background-color: ${props =>
    props.theme.downloadBackgroundColor &&
    !props.backgroundImage &&
    props.theme.downloadBackgroundColor};
  background-image: ${props =>
    props.backgroundImage && `url(${props.backgroundImage})`};
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  text-align: center;
  padding: 50px 20px;
`;
const StyledTitle = styled.h3`
  font-size: 2rem;
  font-weight: 700;
  color: ${props => props.theme.downloadTitleColor};
`;

const StyledSubTitle = styled.h4`
  font-size: 1.3rem;
  font-weight: 400;
  color: ${props => props.theme.downloadSubtitleColor};
`;

const StyledStoreLink = styled.a`
  margin: 0 5px;
`;

const StyledDownloadLinksWrapper = styled.div`
  margin-top: 30px;
`;

const StyledDonateUrl = styled.a`
  background-color: ${props => props.theme.donateButtonColor};
  color: ${props => props.theme.donateButtonTextColor};
  padding: 10px 15px;
  font-size: 1rem;
  border-radius: 5px;
  &:hover {
    color: ${props => props.theme.donateButtonTextColor};
    text-decoration: none;
  }
`;

const DownloadMobileApp = ({
  title,
  subtitle,
  backgroundImage,
  appStoreLink,
  playStoreLink,
  donateUrl,
  donateTitle
}) => (
  <StyledDownloadMobileApp backgroundImage={backgroundImage}>
    <StyledTitle>{title}</StyledTitle>
    {subtitle && <StyledSubTitle>{subtitle}</StyledSubTitle>}
    <StyledDownloadLinksWrapper>
      {appStoreLink && (
        <StyledStoreLink href={appStoreLink}>
          <img src={appStoreImg} alt="Download on App Store" />
        </StyledStoreLink>
      )}
      {playStoreLink && (
        <StyledStoreLink href={playStoreLink}>
          <img src={playStoreImg} alt="Download on Play Store" />
        </StyledStoreLink>
      )}
    </StyledDownloadLinksWrapper>
    <StyledDownloadLinksWrapper>
      <StyledDonateUrl
        href={donateUrl ? donateUrl : "/"}
        target="_blank"
        rel="noopener noreferrer"
      >
        {donateTitle ? donateTitle : "Donate"}
      </StyledDonateUrl>
    </StyledDownloadLinksWrapper>
  </StyledDownloadMobileApp>
);

export default DownloadMobileApp;

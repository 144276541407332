import http from "./http";
import qs from "query-string";

import * as cookiesService from "./cookiesService";

import { SESSIONS_API_ENDPOINT } from "./endpoints";

export const fetchNewSession = () => {
  return http.post(SESSIONS_API_ENDPOINT);
};

export const sessionIdFromUrl = () => {
  const queryParams = qs.parse(window.location.search);
  if (queryParams.spd_session && queryParams.spd_session.length > 0) {
    return queryParams.spd_session;
  }
  return false;
};

export const resolveSession = () => {
  const queryParams = qs.parse(window.location.search);
  if (queryParams.spd_session && queryParams.spd_session.length > 0) {
    const session = queryParams.spd_session;
    if (!cookiesService.hasSameSessionCookie(session)) {
      cookiesService.setSessionCookie(session);
    }
    return Promise.resolve(session);
  } else {
    if (!cookiesService.hasUniqueCookie()) {
      return fetchNewSession().then(result => {
        const { session } = result.data.data;
        if (session && session.length > 0) {
          cookiesService.setSessionCookie(session);
          return session;
        } else {
          const localSessionUuid = cookiesService.generateUuid();
          cookiesService.setSessionCookie(localSessionUuid);
          return Promise.resolve(localSessionUuid);
        }
      });
    } else {
      return Promise.resolve(cookiesService.getUniqueCookie());
    }
  }
};

export const setHttpSession = session => {
  http.interceptors.request.use(
    function(config) {
      config.params = {
        ...config.params,
        spd_session: session
      };
      return config;
    },
    function(error) {
      return Promise.reject(error);
    }
  );
};

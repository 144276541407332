import http from "./http";
import { sprintf } from "sprintf-js";

import { CATEGORIES_VIEW_API_ENDPOINT } from "./endpoints";

export function fetchCategory(worldId, id) {
  return http.get(
    sprintf(CATEGORIES_VIEW_API_ENDPOINT, {
      worldId: worldId,
      categoryId: id
    })
  );
}

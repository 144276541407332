import React from "react";
import { withRouter } from "react-router-dom";
import styled, { ThemeProvider, createGlobalStyle } from "styled-components";
import Measure from "react-measure";
import qs from "query-string";
import throttle from "lodash/throttle";

import { ROUTE_INDEX } from "../../routes";

import ChallengeHeader from "../../components/ChallengeHeader";
import Footer from "../../components/Footer";

import Game from "../../components/Challenges/Game";
import Quiz from "../../components/Challenges/Quiz";
import Video from "../../components/Challenges/Video";
import Navigation from "../../components/Navigation";
import { Helmet } from "react-helmet";
import GoogleAnalyticsPageView from "../../components/Tracking/GoogleAnalyticsPageView/GoogleAnalyticsPageView";
import AppContext from "../../AppContext";
import * as challengeService from "../../services/challengeService";
import renderComponent from "../../components/RenderComponent";
import ChallengeRecentView from "../../components/Tracking/ChallengeRecentView";

const challengeComponents = {
  game: Game,
  quiz: Quiz,
  video: Video
};
const challengeComponent = type => {
  return challengeComponents[type] || null;
};

const PageStyle = createGlobalStyle`
  body {
    background-color: ${props => props.theme.journeyBackgroundColor};
      padding-top: ${props => props.paddingTop && `${props.paddingTop}px`};

  }
`;

const StyledChallenge = styled.div`
  background-color: ${props => props.theme.journeyBackgroundColor};
`;

const StyledHeaderWrapper = styled.div`
  background-color: ${props =>
    props.theme.challengeHeaderBackgroundColor &&
    props.theme.challengeHeaderBackgroundColor};
`;

const NavigationWrapper = React.forwardRef(({ children, ...rest }, ref) => (
  <div ref={ref} {...rest}>
    {children}
  </div>
));

class Challenge extends React.PureComponent {
  constructor(props) {
    super(props);

    const params = qs.parse(this.props.location.search);

    let showNavigation = true;
    let showFooter = true;

    if (
      params.spd_source &&
      params.spd_source !== "" &&
      params.spd_source === "mobile"
    ) {
      showNavigation = false;
      showFooter = false;
    }

    this.state = {
      showFooter,
      headerHeight: null, // this is calculated dinamically because of the logo size in the header,
      showNavigation,
      scrollPos: 0,
      isFetching: true,
      challenge: null,
      layout: null,
      source: params.source
    };

    if (showNavigation) {
      this.handleScroll = throttle(this.handleScroll, 50);
    }
  }

  componentDidMount() {
    this.fetchChallenge();

    window.scrollTo({ top: 0, behavior: "smooth" });

    if (this.state.showNavigation) {
      window.addEventListener("scroll", this.handleScroll);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.match.params.challenge !== prevProps.match.params.challenge
    ) {
      this.fetchChallenge();
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    const { scrollPos } = this.state;

    const top = document.body.getBoundingClientRect().top;

    this.setState({
      scrollPos: top > 0 ? 0 : top,
      showNavigation:
        top < 0 && Math.abs(top) > this.state.headerHeight
          ? top > scrollPos
          : true
    });
  };

  onHeaderHeight = measure => {
    this.setState({
      headerHeight: measure.dimensions.height
    });
  };

  fetchChallenge = () => {
    this.setState({
      isFetching: true
    });

    const challenge = this.props.match.params.challenge;
    const { world } = this.context;

    if (world) {
      return challengeService
        .fetchChallenge(world.domain, challenge)
        .then(result => {
          const { challenge, layout } = result.data.data;
          this.setState({
            challenge,
            layout,
            isFetching: false
          });
        });
    }
  };

  onToggleFavoriteChallenge = (challenge, isFavorite) => {
    const { toggleFavoriteChallenge } = this.context;

    this.setState({
      ...this.state,
      challenge: { ...this.state.challenge, isFavorite: !isFavorite }
    });
    return toggleFavoriteChallenge(challenge, isFavorite);
  };

  render() {
    const { world, toggleFavoriteChallenge } = this.context;

    let pageTitle = `SPARKd`;
    let ChallengeComponent = null;

    if (this.state.challenge) {
      ChallengeComponent = challengeComponent(this.state.challenge.type);

      pageTitle = `${this.state.challenge.name} - SPARKd`;
    }

    return (
      <ThemeProvider theme={world.theme}>
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>
        <StyledChallenge>
          <StyledHeaderWrapper>
            {this.state.showNavigation && (
              <Measure
                bounds
                onResize={contentRect => {
                  this.onHeaderHeight({ dimensions: contentRect.bounds });
                }}
              >
                {({ measureRef }) => (
                  <NavigationWrapper
                    ref={measureRef}
                    className={`navigation-wrapper fixed-top ${
                      this.state.showNavigation ? "active" : "hidden"
                    }`}
                    style={
                      this.state.headerHeight
                        ? {
                            top: this.state.showNavigation
                              ? "0px"
                              : `-${this.state.headerHeight}px`
                          }
                        : {
                            top: "0px"
                          }
                    }
                  >
                    <Navigation
                      className="d-inline-block"
                      backTo={ROUTE_INDEX}
                      world={world}
                      height={this.state.headerHeight}
                      challenge={this.state.challenge}
                      onToggleFavoriteChallenge={this.onToggleFavoriteChallenge}
                    />
                  </NavigationWrapper>
                )}
              </Measure>
            )}

            <ChallengeHeader
              logo={world.theme.headerLogo}
              title={this.state.challenge ? this.state.challenge.name : null}
              subtitle={
                this.state.challenge ? this.state.challenge.description : null
              }
              backToWorld={world.slug}
            />
          </StyledHeaderWrapper>

          <PageStyle paddingTop={this.state.headerHeight} />

          {world &&
            this.state.challenge &&
            ChallengeComponent && (
              <ChallengeComponent
                challenge={this.state.challenge}
                world={world}
              />
            )}

          <GoogleAnalyticsPageView pageTitle={pageTitle} />
          {this.state.challenge && (
            <ChallengeRecentView challenge={this.state.challenge} />
          )}
          {/*<Explore*/}
          {/*  title={*/}
          {/*    world.exploreMoreTitle && world.exploreMoreTitle !== ""*/}
          {/*      ? world.exploreMoreTitle*/}
          {/*      : `Explore more from ${category.name}`*/}
          {/*  }*/}
          {/*>*/}
          {/*  {<CategoryChallengesList hideTitle={true} category={category} />}*/}
          {/*</Explore>*/}

          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                {this.state.layout &&
                  this.state.layout.length > 0 &&
                  this.state.layout.map(layoutComponent => {
                    if (layoutComponent.data.length === 0) {
                      return null;
                    }

                    const LayoutComponent = renderComponent(
                      layoutComponent.type
                    );

                    return LayoutComponent ? (
                      <LayoutComponent
                        key={`c-${Math.random()}`}
                        {...layoutComponent}
                        toggleFavoriteChallenge={toggleFavoriteChallenge}
                        currentChallenge={this.state.challenge}
                      />
                    ) : null;
                  })}
              </div>
            </div>
          </div>

          {this.state.showFooter && (
            <Footer
              logo={world.theme.footerLogo}
              aboutLink="/"
              termsLink="/"
              privacyLink="/"
            />
          )}
        </StyledChallenge>
      </ThemeProvider>
    );
  }
}

Challenge.contextType = AppContext;

export default withRouter(Challenge);

import http from "./http";
import { sprintf } from "sprintf-js";

import {
  FAVORITES_LIKE_API_ENDPOINT,
  FAVORITES_UNLIKE_API_ENDPOINT
} from "./endpoints";

export const addToFavorites = (worldId, challengeId) => {
  return http.get(
    sprintf(FAVORITES_LIKE_API_ENDPOINT, { worldId, challengeId })
  );
};

export const removeFromFavorites = (worldId, challengeId) => {
  return http.get(
    sprintf(FAVORITES_UNLIKE_API_ENDPOINT, { worldId, challengeId })
  );
};

const BASE_API_ENDPOINT = process.env.REACT_APP_SPARKD_BASE_API_ENDPOINT
  ? process.env.REACT_APP_SPARKD_BASE_API_ENDPOINT
  : "https://api.dev.sparkd.com/api";

export const WORLDS_API_ENDPOINT = `${BASE_API_ENDPOINT}`;
export const WORLDS_VIEW_API_ENDPOINT = `${WORLDS_API_ENDPOINT}/worlds/%(worldId)s`;

export const CHALLENGES_API_ENDPOINT = `${WORLDS_VIEW_API_ENDPOINT}/challenges`;
export const CHALLENGES_VIEW_API_ENDPOINT = `${CHALLENGES_API_ENDPOINT}/%(challengeId)s`;

export const CATEGORIES_API_ENDPOINT = `${WORLDS_VIEW_API_ENDPOINT}/categories`;
export const CATEGORIES_VIEW_API_ENDPOINT = `${CATEGORIES_API_ENDPOINT}/%(categoryId)s`;

export const SESSIONS_API_ENDPOINT = `${BASE_API_ENDPOINT}/sessions`;

export const FAVORITES_API_ENDPOINT = `${CHALLENGES_VIEW_API_ENDPOINT}`;
export const FAVORITES_LIKE_API_ENDPOINT = `${FAVORITES_API_ENDPOINT}/like`;
export const FAVORITES_UNLIKE_API_ENDPOINT = `${FAVORITES_API_ENDPOINT}/unlike`;

export const RECENT_API_ENDPOINT = `${CHALLENGES_VIEW_API_ENDPOINT}/recent`;

import Cookies from "universal-cookie";
import { v4 as uuidv4 } from "uuid";

const sparkedCookieID = "spd_session";
const sparkdFirstTimeVisitCookie = "spd_visited";

export function generateUuid() {
  return uuidv4();
}

export function hasUniqueCookie() {
  const cookies = new Cookies();
  return !!cookies.get(sparkedCookieID);
}

export function getUniqueCookie() {
  const cookies = new Cookies();
  return cookies.get(sparkedCookieID);
}

export function hasFirstTimeVisitCookie(brand) {
  const cookies = new Cookies();

  if (!cookies.get(sparkdFirstTimeVisitCookie)) {
    return false;
  }

  const visited = cookies.get(sparkdFirstTimeVisitCookie);
  if (visited.length === 0) {
    return false;
  }
  return visited.indexOf(brand.toLowerCase()) > -1;
}

export function setFirstTimeVisitCookie(brand) {
  const cookies = new Cookies();

  const visited = cookies.get(sparkdFirstTimeVisitCookie) || [];
  cookies.set(sparkdFirstTimeVisitCookie, visited.concat(brand.toLowerCase()));
}

export function hasSameSessionCookie(session) {
  const cookies = new Cookies();
  const sessionCookie = cookies.get(sparkedCookieID);

  return session === sessionCookie;
}

export function setSessionCookie(session) {
  const cookies = new Cookies();
  cookies.set(sparkedCookieID, session);
}

export function cookieName() {
  return sparkedCookieID;
}

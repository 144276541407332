import React from "react";
import { withRouter } from "react-router-dom";
import AppContext from "../../../AppContext";

class ChallengeRecentView extends React.Component {
  componentDidMount() {
    const { addChallengeToRecent } = this.context;

    addChallengeToRecent(this.props.challenge);
  }

  render() {
    return null;
  }
}

ChallengeRecentView.contextType = AppContext;

export default withRouter(ChallengeRecentView);

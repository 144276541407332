import React from "react";
import styled from "styled-components";
import Card from "../Card";

const StyledSmallCard = styled.div`
  & > .card {
    max-width: 196px;
    min-height: 215px;
    max-height: 215px;
  }

  & > .card .card-title {
    font-size: 1rem;
    line-height: 1.3125rem;
  }

  & > .card .card-image {
    max-height: 127px;
    min-height: 127px;
    height: 127px;
    min-width: 100%;
  }
`;

const SmallCard = props => (
  <StyledSmallCard>
    <Card {...props} />
  </StyledSmallCard>
);

SmallCard.defaultProps = {
  className: "small-card"
};

export default SmallCard;

import React from "react";
import styled from "styled-components";
import { generatePath } from "react-router";
import { ROUTE_CATEGORY } from "../../routes";

import SourceAwareLink from "../SourceAwareLink";
import SmallCard from "../Cards/SmallCard";

const StyledOtherCategories = styled.div`
  padding: 0;
`;

const StyledCategoryName = styled.span`
  display: block;
  width: 100%;
  font-size: 1.375rem;
  font-weight: 600;
  padding-left: 24px;
`;

const StyledOtherCategoriesWrapper = styled.ul`
  padding-left: 15px;
  padding-right: 15px;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    display: none;
  }
  & > li {
    display: inline-block;
    padding: 10px;
    margin-right: 5px;
    vertical-align: top;
  }
`;

const OtherCategories = ({ config, data, toggleFavoriteChallenge }) => (
  <StyledOtherCategories className="row">
    <StyledCategoryName>Other Categories</StyledCategoryName>
    <StyledOtherCategoriesWrapper>
      {data &&
        data.length > 0 &&
        data.map(category => (
          <li key={`categories-list-${category.id}`}>
            <SourceAwareLink
              className="d-inline-block"
              to={generatePath(ROUTE_CATEGORY, {
                category: category.id
              })}
            >
              <SmallCard
                title={category.name}
                thumb={category.image}
                isNew={!!category.isNew}
                newTitle={!!category.isNew && `New ${category.type}`}
                canBeFavorite={false}
              />
            </SourceAwareLink>
          </li>
        ))}
    </StyledOtherCategoriesWrapper>
  </StyledOtherCategories>
);

export default OtherCategories;

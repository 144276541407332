export function pageView(path, location, title = null) {
  if (process.env.NODE_ENV !== "production") return;

  const gtag = window.gtag;
  if (typeof gtag !== "function") return;

  // prettier-ignore
  gtag("event", "page_view", {
      'page_location': location,
      'page_path': path,
      'page_title': title ? title : document.title
  });
}

import React from "react";
import styled from "styled-components";
import { generatePath } from "react-router";
import { ROUTE_CHALLENGE } from "../../routes";

import SourceAwareLink from "../SourceAwareLink";
import LargeCard from "../Cards/LargeCard";

const StyledRecentList = styled.div`
  padding: 0;
`;

const StyledCategoryName = styled.span`
  display: block;
  width: 100%;
  font-size: 1.375rem;
  font-weight: 600;
  padding-left: 24px;
`;

const StyledRecentListWrapper = styled.ul`
  padding-left: 15px;
  padding-right: 15px;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    display: none;
  }
  & > li {
    display: inline-block;
    padding: 10px;
    margin-right: 5px;
    vertical-align: top;
  }
`;

const RecentList = ({ config, data, toggleFavoriteChallenge }) => (
  <StyledRecentList className="row">
    {config.titleVisible && (
      <StyledCategoryName>{config.title}</StyledCategoryName>
    )}
    <StyledRecentListWrapper>
      {data &&
        data.length > 0 &&
        data.map(challenge => (
          <li key={`favorites-challenges-${challenge.id}`}>
            {challenge.type !== "link" ? (
              <SourceAwareLink
                className="d-inline-block"
                to={generatePath(ROUTE_CHALLENGE, {
                  challenge: challenge.id
                })}
              >
                <LargeCard
                  title={challenge.name}
                  thumb={challenge.image}
                  isNew={!!challenge.isNew}
                  newTitle={!!challenge.isNew && `New ${challenge.type}`}
                  favorite={challenge.isFavorite}
                  toggleFavoriteChallenge={toggleFavoriteChallenge}
                  canBeFavorite={true}
                  id={challenge.id}
                />
              </SourceAwareLink>
            ) : (
              challenge.type === "link" && (
                <a
                  href={challenge.embed}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <LargeCard
                    title={challenge.name}
                    thumb={challenge.image}
                    isNew={!!challenge.isNew}
                    newTitle={!!challenge.isNew && `New ${challenge.type}`}
                    favorite={challenge.isFavorite}
                    toggleFavoriteChallenge={toggleFavoriteChallenge}
                    canBeFavorite={true}
                    id={challenge.id}
                  />
                </a>
              )
            )}
          </li>
        ))}
    </StyledRecentListWrapper>
  </StyledRecentList>
);

export default RecentList;

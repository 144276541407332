import React from "react";
import { withRouter } from "react-router-dom";
import styled, { ThemeProvider } from "styled-components";
import qs from "query-string";
import * as categoryService from "../../services/categoryService";

import { generatePath } from "react-router";

import { ROUTE_INDEX, ROUTE_CHALLENGE } from "../../routes";

import Footer from "../../components/Footer";
import Navigation from "../../components/Navigation";
import SourceAwareLink from "../../components/SourceAwareLink";
import { Helmet } from "react-helmet";
import GoogleAnalyticsPageView from "../../components/Tracking/GoogleAnalyticsPageView/GoogleAnalyticsPageView";
import AppContext from "../../AppContext";
import FullWidthCard from "../../components/Cards/FullWidthCard";
import Skeleton from "react-loading-skeleton";
import renderComponent from "../../components/RenderComponent";

// const PageStyle = createGlobalStyle`
//   body {
//     background-color: ${props => props.theme.worldBackgroundColor};
//       padding-top: ${props => props.paddingTop && `${props.paddingTop}px`};
//
//   }
// `;

const StyledCategory = styled.div`
  background-color: ${props => props.theme.worldBackgroundColor};
`;

const StyledCategoryContent = styled.div`
  padding: 30px 15px;
`;

const StyledHeaderWrapper = styled.div`
  background-color: ${props =>
    props.theme.challengeHeaderBackgroundColor &&
    props.theme.challengeHeaderBackgroundColor};
`;

const StyledCategoryTitle = styled.span`
  font-size: 1.5rem;
  font-weight: 600;
  display: block;
  width: 100%;
  padding-left: 5px;
  margin-bottom: 15px;
`;

const topChallengesRandomArray = [2, 4];

class Category extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      showFooter: true,
      isFetching: true,
      category: null,
      layout: null
    };
  }

  componentDidMount() {
    this.fetchCategory();
    const params = qs.parse(this.props.location.search);

    window.scrollTo({ top: 0, behavior: "smooth" });

    if (params.source && params.source !== "" && params.source === "mobile") {
      this.setState({
        showFooter: false
      });
    }

    window.addEventListener("scroll", this.handleScroll);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.match.params.category !== prevProps.match.params.category) {
      this.fetchCategory();
      window.scrollTo({ top: 0, behavior: "smooth" });
      const params = qs.parse(this.props.location.search);
      if (params.source && params.source !== "" && params.source === "mobile") {
        this.setState({
          showFooter: false
        });
      }
    }
  }

  fetchCategory = () => {
    this.setState({
      isFetching: true
    });

    const category = this.props.match.params.category;
    const { world } = this.context;

    categoryService.fetchCategory(world.domain, category).then(result => {
      const { category, layout } = result.data.data;
      this.setState({
        category,
        layout,
        isFetching: false
      });
    });
  };

  render() {
    const { world, toggleFavoriteChallenge } = this.context;

    let topChallenges = null;
    let bottomChallenges = null;

    let pageTitle = `SPARKd`;

    if (!this.state.isFetching) {
      // max 4 challenges, min 2 challenges
      const numberOfTopChallenges =
        topChallengesRandomArray[Math.floor(Math.random() * (2 - 1 + 1))];

      if (this.state.category.challenges.length > numberOfTopChallenges) {
        topChallenges = this.state.category.challenges.slice(
          0,
          numberOfTopChallenges
        );
        bottomChallenges = this.state.category.challenges.slice(
          numberOfTopChallenges,
          this.state.category.challenges.length
        );
      } else {
        topChallenges = this.state.category.challenges;
      }

      pageTitle = `${this.state.category.name} - SPARKd`;
    }

    return (
      <ThemeProvider theme={world.theme}>
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>
        <StyledCategory>
          <StyledHeaderWrapper>
            <Navigation
              className="d-inline-block"
              backTo={ROUTE_INDEX}
              world={world}
              height={this.state.headerHeight}
            />
          </StyledHeaderWrapper>
          <GoogleAnalyticsPageView pageTitle={pageTitle} />

          <StyledCategoryContent className="container-fluid">
            <div className="row">
              <div className="col-12">
                <StyledCategoryTitle>
                  {this.state.category && this.state.category.name ? (
                    `${this.state.category.name} Challenges`
                  ) : (
                    <Skeleton />
                  )}
                </StyledCategoryTitle>
              </div>

              {topChallenges && topChallenges.length > 0
                ? topChallenges.map((challenge, index) => (
                    <div
                      key={`challenge-${challenge.id}`}
                      className={`col-12 col-sm-6 col-lg-6 text-center`}
                    >
                      {challenge.type !== "link" && (
                        <SourceAwareLink
                          className="d-inline-block"
                          to={generatePath(ROUTE_CHALLENGE, {
                            category: this.state.id,
                            challenge: challenge.id
                          })}
                        >
                          <FullWidthCard
                            title={challenge.name}
                            thumb={challenge.image}
                            isNew={!!challenge.isNew}
                            newTitle={
                              !!challenge.isNew && `New ${challenge.type}`
                            }
                          />
                        </SourceAwareLink>
                      )}
                      {challenge.type === "link" && (
                        <a
                          href={challenge.embed}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <FullWidthCard
                            title={challenge.name}
                            thumb={challenge.image}
                            isNew={!!challenge.isNew}
                            newTitle={
                              !!challenge.isNew && `New ${challenge.type}`
                            }
                          />
                        </a>
                      )}
                    </div>
                  ))
                : Array(5)
                    .fill()
                    .map((item, index) => (
                      <div
                        key={`${item}-${index}`}
                        className={`col-12 col-sm-6 col-lg-6 text-center`}
                      >
                        <FullWidthCard />
                      </div>
                    ))}
            </div>
            <div className="row">
              <div className="col-12">
                {this.state.layout &&
                  this.state.layout.length > 0 &&
                  this.state.layout.map(layoutComponent => {
                    if (layoutComponent.data.length === 0) {
                      return null;
                    }

                    const LayoutComponent = renderComponent(
                      layoutComponent.type
                    );

                    return LayoutComponent ? (
                      <LayoutComponent
                        key={`c-${Math.random()}`}
                        {...layoutComponent}
                        toggleFavoriteChallenge={toggleFavoriteChallenge}
                      />
                    ) : null;
                  })}
              </div>
            </div>
            <div className="row">
              {bottomChallenges &&
                bottomChallenges.length > 0 &&
                bottomChallenges.map((challenge, index) => (
                  <div
                    key={`challenge-${challenge.id}`}
                    className={`col-12 col-sm-6 col-lg-6 text-center`}
                  >
                    {challenge.type !== "link" && (
                      <SourceAwareLink
                        className="d-inline-block"
                        to={generatePath(ROUTE_CHALLENGE, {
                          category: this.state.id,
                          challenge: challenge.id
                        })}
                      >
                        <FullWidthCard
                          title={challenge.name}
                          thumb={challenge.image}
                          isNew={!!challenge.isNew}
                          newTitle={
                            !!challenge.isNew && `New ${challenge.type}`
                          }
                        />
                      </SourceAwareLink>
                    )}
                    {challenge.type === "link" && (
                      <a
                        href={challenge.embed}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FullWidthCard
                          title={challenge.name}
                          thumb={challenge.image}
                          isNew={!!challenge.isNew}
                          newTitle={
                            !!challenge.isNew && `New ${challenge.type}`
                          }
                        />
                      </a>
                    )}
                  </div>
                ))}
            </div>
          </StyledCategoryContent>
          {this.state.showFooter && (
            <Footer
              logo={world.theme.footerLogo}
              aboutLink="/"
              termsLink="/"
              privacyLink="/"
            />
          )}
        </StyledCategory>
      </ThemeProvider>
    );
  }
}

Category.contextType = AppContext;

export default withRouter(Category);

import React from "react";
import styled from "styled-components";

import Icon from "../Icon";

const StyledIconFavorites = styled.span`
  position: absolute;
  z-index: 1000;
  right: 0;
  display: inline-block;

  & .icon:before {
    color: ${props => props.favorite && "#ff2b3a"};
  }
`;

const IconFavorites = ({ favorite, ...rest }) => (
  <StyledIconFavorites favorite={favorite} {...rest}>
    {favorite ? <Icon icon="favorites" /> : <Icon icon="favorites-outlined" />}
  </StyledIconFavorites>
);

IconFavorites.defaultProps = {
  favorite: false
};

export default IconFavorites;

import FavoritesList from "../FavoritesList";
import NewChallengesList from "../NewChallengesList/NewChallegesList";
import RecentList from "../RecentList";
import CategoriesList from "../CategoriesList";
import CategoryChallengesList from "../CategoryChallengesList";
import ChallengeCategoryList from "../ChallengeCategoryList";
import OtherCategories from "../OtherCategories";

export const FAVORITES_COMPONENT = "favorites";
export const NEW_COMPONENT = "new";
export const RECENT_COMPONENT = "recent";
export const CATEGORY_LIST_COMPONENT = "category-list";
export const CATEGORY_GRID_COMPONENT = "category-grid";
export const CHALLENGE_CATEGORY_LIST_COMPONENT = "challenge-category-list";
export const OTHER_CATEGORIES_COMPONENT = "other-categories";

const componentTypes = {
  [FAVORITES_COMPONENT]: FavoritesList,
  [NEW_COMPONENT]: NewChallengesList,
  [RECENT_COMPONENT]: RecentList,
  [CATEGORY_LIST_COMPONENT]: CategoriesList,
  [CATEGORY_GRID_COMPONENT]: CategoryChallengesList,
  [CHALLENGE_CATEGORY_LIST_COMPONENT]: ChallengeCategoryList,
  [OTHER_CATEGORIES_COMPONENT]: OtherCategories
};

const renderComponent = type => {
  return componentTypes[type] ? componentTypes[type] : null;
};

export default renderComponent;

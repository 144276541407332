import React from "react";
import styled from "styled-components";
import ReactPlayer from "react-player";

const StyledVideoWrapper = styled.div`
  text-align: center;
  position: relative;
  padding-top: 56.25%; /* 720 / 1280 = 0.5625 */
  & .player {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
`;

const StyledCloseButton = styled.button`
  display: block;
  padding: 10px 20px;
  background-color: transparent !important;
  top: -30px;
  right: 0;
  position: absolute;
  color: white;
  z-index: 10;
  text-transform: uppercase;
  font-size: 1.75rem;

  &:hover {
    color: white;
  }
`;

const StyledSkip = styled.span`
  display: inline-block;
  margin: 0px auto;
  position: relative;
  bottom: 0;
  font-size: 0.875rem;
  z-index: 10;
  color: white;
  cursor: pointer;
`;

class VideoCover extends React.Component {
  render() {
    const { src, onClose, onSkip } = this.props;

    return (
      <StyledVideoWrapper>
        <StyledCloseButton
          onClick={() => onClose()}
          type="button"
          className="close"
          data-dismiss="alert"
          aria-label="Close"
        >
          <span>&times;</span>
        </StyledCloseButton>
        <ReactPlayer
          className="player"
          url={src}
          controls
          playsinline={true}
          playing
          autoPlay={true}
          width="100%"
          height="90%"
          volume={0.5}
        />
        <StyledSkip onClick={() => onSkip()}>SKIP</StyledSkip>
      </StyledVideoWrapper>
    );
  }
}

export default VideoCover;

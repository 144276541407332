import React from "react";
import styled from "styled-components";
import { generatePath } from "react-router";
import { ROUTE_CHALLENGE } from "../../routes";

import SourceAwareLink from "../SourceAwareLink";
import SmallCard from "../Cards/SmallCard";

const StyledCategoryChallengesList = styled.div`
  padding: 0;
`;

const StyledCategoryName = styled.span`
  display: block;
  width: 100%;
  font-size: 1.375rem;
  font-weight: 600;
  padding-left: 24px;
`;

const StyledCategoryChallengesListWrapper = styled.ul`
  padding-left: 15px;
  padding-right: 15px;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    display: none;
  }
  & > li {
    display: inline-block;
    padding: 10px;
    margin-right: 5px;
    vertical-align: top;
  }
`;

const CategoryChallengesList = ({ config, data, toggleFavoriteChallenge }) =>
  data.map(category => (
    <StyledCategoryChallengesList
      className="row"
      key={`category-challenges-list-${category.id}`}
    >
      <StyledCategoryName>{category.name}</StyledCategoryName>

      <StyledCategoryChallengesListWrapper>
        {category.challenges &&
          category.challenges.length > 0 &&
          category.challenges.map(challenge => (
            <li key={challenge.id}>
              {challenge.type !== "link" ? (
                <SourceAwareLink
                  className="d-inline-block"
                  to={generatePath(ROUTE_CHALLENGE, {
                    challenge: challenge.id
                  })}
                >
                  <SmallCard
                    title={challenge.name}
                    thumb={challenge.image}
                    isNew={!!challenge.isNew}
                    newTitle={!!challenge.isNew && `New ${challenge.type}`}
                    toggleFavoriteChallenge={toggleFavoriteChallenge}
                    canBeFavorite={true}
                    favorite={challenge.isFavorite}
                    id={challenge.id}
                  />
                </SourceAwareLink>
              ) : (
                challenge.type === "link" && (
                  <a
                    href={challenge.embed}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <SmallCard
                      title={challenge.name}
                      thumb={challenge.image}
                      isNew={!!challenge.isNew}
                      newTitle={!!challenge.isNew && `New ${challenge.type}`}
                      toggleFavoriteChallenge={toggleFavoriteChallenge}
                      canBeFavorite={true}
                      favorite={challenge.isFavorite}
                      id={challenge.id}
                    />
                  </a>
                )
              )}
            </li>
          ))}
      </StyledCategoryChallengesListWrapper>
    </StyledCategoryChallengesList>
  ));

export default CategoryChallengesList;

import React from "react";
import styled from "styled-components";
import ReactPlayer from "react-player";
import { withRouter } from "react-router";
import { fetchChallenge } from "../../../services/challengeService";

const StyledVideoWrapper = styled.div`
  max-height: 600px;
  text-align: center;
  display: flex;
  background-color: black;
  & .player {
    margin: 0px auto;
  }
`;

class Video extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      challenge: null
    };
  }

  componentDidMount() {
    if (this.props.challenge) {
      fetchChallenge(this.props.world.domain, this.props.challenge.id).then(
        result => {
          const { challenge } = result.data.data;
          this.setState({ loaded: true, challenge });
        }
      );
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.challenge.id !== prevProps.challenge.id) {
      this.setState({ loaded: false });
      fetchChallenge(this.props.world.domain, this.props.challenge.id).then(
        result => {
          const { challenge } = result.data.data;
          this.setState({ loaded: true, challenge });
        }
      );
    }
  }

  render() {
    return (
      <StyledVideoWrapper>
        {this.state.challenge && (
          <ReactPlayer
            className="player"
            url={this.state.challenge.embed}
            controls
            playsinline={true}
            playing
          />
        )}
      </StyledVideoWrapper>
    );
  }
}

export default withRouter(Video);

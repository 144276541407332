import React from "react";
import { withRouter } from "react-router-dom";
import { pageView } from "../../../services/googleAnalyticsServices";

class GoogleAnalyticsPageView extends React.Component {
  componentDidMount() {
    const gtag = window.gtag;

    if (typeof gtag === "function") {
      pageView(
        this.props.location.pathname,
        this.props.location.href,
        this.props.pageTitle
      );
    }
  }

  componentDidUpdate(prevProps) {
    const gtag = window.gtag;

    if (prevProps.location.pathname === this.props.location.pathname) {
      return;
    }

    if (typeof gtag === "function") {
      if (this.props.history.action === "PUSH") {
        pageView(
          this.props.location.pathname,
          this.props.location.href,
          this.props.pageTitle
        );
      }
    }
  }

  render() {
    return null;
  }
}

export default withRouter(GoogleAnalyticsPageView);

import React from "react";
import styled from "styled-components";

const StyledHeader = styled.header`
  background-color: ${props =>
    props.theme.headerBackgroundColor && props.theme.headerBackgroundColor};
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 20px;
`;
// const StyledTitle = styled.h2`
//   font-size: ${props => {
//     if (props.textLength < 30) {
//       return "2rem";
//     }
//
//     if (props.textLength > 30 && props.textLength < 50) {
//       return "1.5rem";
//     }
//
//     if (props.textLength > 50) {
//       return "1rem";
//     }
//   }};
//   font-weight: 700;
//   color: ${props => props.theme.headerTitleColor};
// `;

const StyledLogo = styled.img`
  max-height: 64px;
  border: 0;
`;

// const StyledWorldName = styled.h1`
//   font-size: 2rem;
//   font-weight: 700;
//   color: ${props => props.theme.headerTitleColor};
// `;

const Header = ({ name, title, logo, backgroundImage }) => (
  <StyledHeader backgroundImage={backgroundImage}>
    {logo &&
      logo !== "" && (
        <StyledLogo src={logo} alt={title} className="img-fluid" />
      )}
    {/*{(!logo || logo === "") && <StyledWorldName>{name}</StyledWorldName>}*/}
    {/*{title && title.length && (*/}
    {/*  <StyledTitle textLength={title.length}>{title}</StyledTitle>*/}
    {/*)}*/}
  </StyledHeader>
);

export default Header;

import React from "react";
import styled from "styled-components";

const StyledFooter = styled.footer`
  background-color: ${props =>
    props.theme.footerBackgroundColor &&
    !props.theme.footerBackgroundImage &&
    props.theme.footerBackgroundColor};
  background-image: ${props =>
    props.theme.footerBackgroundImage &&
    `url(${props.theme.footerBackgroundImage})`};

  background-repeat: no-repeat;
  background-position: top left;
  background-size: 100% 100%;

  padding: 0 10px;
  padding-top: 30px;

  & ul {
    margin: 0px auto;
  }

  & ul li {
    display: inline-block;
    margin: 5px 10px;
  }

  & ul li a {
    font-size: 0.9rem;
    color: ${props =>
      props.theme.footerTextColor && props.theme.footerTextColor};
  }

  & ul li a:hover {
    text-decoration: none;
  }
`;

const StyledLogo = styled.img`
  display: block;
  margin: 0px auto;
  margin-bottom: 30px;
  max-width: 70px;
  border: 0;
`;

const Footer = ({ logo, aboutLink, termsLink, privacyLink }) => (
  <StyledFooter>
    {logo && logo !== "" && (
      <div className="d-block">
        <StyledLogo src={logo} alt="" />
      </div>
    )}
    <div className="d-block">
      <ul className="list-unstyled text-center">
        {aboutLink && (
          <li>
            <a href={aboutLink}>About</a>
          </li>
        )}
        {termsLink && (
          <li>
            <a href={termsLink}>Terms and conditions</a>
          </li>
        )}
        {privacyLink && (
          <li>
            <a href={privacyLink}>Privacy policy</a>
          </li>
        )}
      </ul>
    </div>
  </StyledFooter>
);
export default Footer;

import React from "react";
import { withRouter } from "react-router-dom";
import { ThemeProvider, createGlobalStyle } from "styled-components";
import qs from "query-string";
import { Helmet } from "react-helmet";

import * as cookesService from "../../services/cookiesService";

import AppContext from "../../AppContext";

import Header from "../../components/Header";
import Content from "../../components/Content";
import DownloadMobileApp from "../../components/DownloadMobileApp";
import Footer from "../../components/Footer";
import Modal from "../../components/Modal";
import VideoCover from "../../components/Cover/VideoCover";

import renderComponent from "../../components/RenderComponent";

import GoogleAnalyticsPageView from "../../components/Tracking/GoogleAnalyticsPageView";

const PageStyle = createGlobalStyle`
  body {
    background-color: ${props => props.theme.worldBackgroundColor || ""};
    padding-top: 0px;
  }
`;

class Brand extends React.PureComponent {
  constructor(props) {
    super(props);

    const params = qs.parse(this.props.location.search);

    let showNavigation = true;
    let showFooter = true;
    let showDownload = true;

    if (
      params.spd_source &&
      params.spd_source !== "" &&
      params.spd_source === "mobile"
    ) {
      showNavigation = false;
      showFooter = false;
      showDownload = false;
    }

    this.state = {
      showCoverModal: false,
      showDownload,
      showNavigation,
      showFooter
    };
  }

  componentDidMount() {
    const { world } = this.context;
    window.scrollTo({ top: 0 });
    const showCoverModal =
      world.coverVideo && !cookesService.hasFirstTimeVisitCookie(world.name);

    this.setState({
      showCoverModal: showCoverModal
    });

    if (
      world.coverVideo &&
      !cookesService.hasFirstTimeVisitCookie(world.name)
    ) {
      cookesService.setFirstTimeVisitCookie(world.name);
    }
  }

  hideCoverModal = () => {
    this.setState({
      ...this.state,
      showCoverModal: false
    });
  };

  render() {
    const { world, toggleFavoriteChallenge } = this.context;

    const pageTitle = `${world.name} - SPARKd`;

    return (
      <ThemeProvider theme={world.theme}>
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>
        <PageStyle />

        <GoogleAnalyticsPageView pageTitle={pageTitle} />

        <Content>
          {world.layout &&
            world.layout.length > 0 &&
            world.layout.map(layoutComponent => {
              if (layoutComponent.data.length === 0) {
                return null;
              }

              const LayoutComponent = renderComponent(layoutComponent.type);

              return LayoutComponent ? (
                <LayoutComponent
                  key={`c-${Math.random()}`}
                  {...layoutComponent}
                  toggleFavoriteChallenge={toggleFavoriteChallenge}
                />
              ) : null;
            })}
        </Content>

        {this.state.showDownload && (
          <DownloadMobileApp
            backgroundImage={world.theme.downloadBackgroundImage}
            title={world.downloadTitle}
            subtitle={world.downloadSubtitle}
            appStoreLink={world.appStoreUrl}
            playStoreLink={world.playStoreUrl}
            donateUrl={world.donateUrl}
            donateTitle={world.donateTitle}
          />
        )}

        {this.state.showDownload && (
          <Footer
            logo={world.theme.footerLogo}
            aboutLink="/"
            termsLink="/"
            privacyLink="/"
          />
        )}

        {this.state.showCoverModal && (
          <Modal onCloseModal={this.hideCoverModal}>
            {world.coverVideo && (
              <VideoCover
                onClose={this.hideCoverModal}
                onSkip={this.hideCoverModal}
                src={world.coverVideo}
              />
            )}
          </Modal>
        )}
      </ThemeProvider>
    );
  }
}

Brand.contextType = AppContext;

export default withRouter(Brand);

import React from "react";
import "./App.css";
import { Route, Switch } from "react-router-dom";
import qs from "query-string";
import * as sessionService from "./services/sessionService";
import * as cookiesService from "./services/cookiesService";
import * as favoritesService from "./services/favoritesService";
import * as recentService from "./services/recentService";

import routes from "./routes";

import AppContext from "./AppContext";
import { appendToLayout, updateLayout, removeFromLayout } from "./updaters";
import {
  FAVORITES_COMPONENT,
  RECENT_COMPONENT
} from "./components/RenderComponent/RenderComponent";

// we need to map the `scale` prop we define below
// to the transform style property
function mapStyles(styles) {
  return {
    opacity: styles.opacity,
    right: `${styles.right}%`
  };
}

// child matches will...
const bounceTransition = {
  // start in a transparent, upscaled state
  atEnter: {
    // opacity: 0,
    right: 0,
    top: 0,
    zIndex: 10000
  },
  // leave in a transparent, downscaled state
  atLeave: {
    // opacity: bounce(0),
    right: 100,
    top: 0,
    zIndex: 0
  },
  // and rest at an opaque, normally-scaled state
  atActive: {
    // opacity: bounce(1),
    right: 0,
    top: 0,
    zIndex: 10000
  }
};

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      world: this.props.world,
      toggleFavoriteChallenge: this.toggleFavoriteChallenge,
      addChallengeToRecent: this.addChallengeToRecent
    };
  }

  toggleFavoriteChallenge = (challenge, isFavorite) => {
    isFavorite
      ? favoritesService
          .removeFromFavorites(this.state.world.domain, challenge)
          .then(result => {
            const { challenge } = result.data.data;
            this.removeChallengeFromFavorite(challenge);
          })
      : favoritesService
          .addToFavorites(this.state.world.domain, challenge)
          .then(result => {
            const { challenge } = result.data.data;
            this.addChallengeToFavorite(challenge);
          });

    const newLayout = this.state.world.layout.map(category => {
      return {
        ...category,
        data: updateLayout(category.type, category.data, challenge, {
          isFavorite: !isFavorite
        })
      };
    });
    this.setState({ world: { ...this.state.world, layout: newLayout } });
  };

  addChallengeToFavorite = challenge => {
    const newLayout = this.state.world.layout.map(category => {
      if (category.type === FAVORITES_COMPONENT) {
        return {
          ...category,
          data: appendToLayout(category.type, category.data, challenge)
        };
      }
      return { ...category };
    });
    this.setState({ world: { ...this.state.world, layout: newLayout } });
  };

  removeChallengeFromFavorite = challenge => {
    const newLayout = this.state.world.layout.map(category => {
      if (category.type === FAVORITES_COMPONENT) {
        return {
          ...category,
          data: removeFromLayout(category.type, category.data, challenge.id)
        };
      }

      return {
        ...category
      };
    });
    this.setState({ world: { ...this.state.world, layout: newLayout } });
  };

  addChallengeToRecent = challenge => {
    recentService.addToRecent(this.state.world.domain, challenge.id);
    const newLayout = this.state.world.layout.map(category => {
      if (category.type === RECENT_COMPONENT) {
        return {
          ...category,
          data: appendToLayout(RECENT_COMPONENT, category.data, challenge)
        };
      }
      return {
        ...category
      };
    });
    this.setState({ world: { ...this.state.world, layout: newLayout } });
  };

  componentDidMount() {
    const queryParams = qs.parse(window.location.search);
    if (queryParams.spd_session && queryParams.spd_session.length > 0) {
      const session = queryParams.spd_session;
      if (!cookiesService.hasSameSessionCookie(session)) {
        cookiesService.setSessionCookie(session);
      }
    } else {
      if (!cookiesService.hasUniqueCookie()) {
        sessionService.fetchNewSession().then(result => {
          const { session } = result.data.data;
          if (session && session.length > 0) {
            cookiesService.setSessionCookie(session);
          } else {
            cookiesService.setSessionCookie(cookiesService.generateUuid());
          }
        });
      }
    }
  }

  render() {
    return (
      <AppContext.Provider value={this.state}>
        <div className="App">
          <Switch
            atEnter={bounceTransition.atEnter}
            atLeave={bounceTransition.atLeave}
            atActive={bounceTransition.atActive}
            mapStyles={mapStyles}
            className="route-wrapper"
          >
            {routes.map(
              (route, index) =>
                route.path ? (
                  <Route
                    key={index}
                    path={route.path}
                    exact={route.exact}
                    component={route.component}
                  />
                ) : (
                  <Route key={index} component={route.component} />
                )
            )}
          </Switch>
        </div>
      </AppContext.Provider>
    );
  }
}

export default App;

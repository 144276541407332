import React from "react";
import styled from "styled-components";
import Skeleton from "react-loading-skeleton";

const StyledChallengeHeader = styled.div`
  background-color: ${props =>
    props.theme.challengeHeaderBackgroundColor &&
    props.theme.challengeHeaderBackgroundColor};
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 20px;
  text-align: center;
`;
const StyledTitle = styled.h1`
  font-size: ${props => {
    if (props.textLength < 30) {
      return "1.75rem";
    }

    if (props.textLength > 30 && props.textLength < 50) {
      return "1rem";
    }

    if (props.textLength > 50) {
      return "0.9rem";
    }
  }};
  font-weight: 900;
  line-height: ${props => {
    if (props.textLength < 30) {
      return "2.125rem";
    }

    if (props.textLength > 30 && props.textLength < 50) {
      return "1.5rem";
    }

    if (props.textLength > 50) {
      return "0.9rem";
    }
  }};
  color: ${props => props.theme.challengeHeaderTitleColor};
  margin-bottom: 15px;
`;

const StyledSubTitle = styled.h1`
  font-size: 1rem;
  color: ${props => props.theme.challengeHeaderTitleColor};
  margin-bottom: 10px;
  padding-bottom: 10px;
`;

// const StyledImage = styled.img`
//   margin-bottom: 20px;
//   @media (min-width: 1280px) {
//     height: 500px;
//   }
// `;

const ChallengeHeader = ({ title, subtitle, logo, image }) => (
  <StyledChallengeHeader>
    {/*
      <StyledImage src={image} className="img-fluid w-100" alt="title" />
    */}
    {title ? (
      <StyledTitle textLength={title.length}>{title}</StyledTitle>
    ) : (
      <StyledTitle>
        <Skeleton height={35} />
      </StyledTitle>
    )}
    {subtitle ? (
      <StyledSubTitle>{subtitle}</StyledSubTitle>
    ) : (
      <StyledSubTitle>
        <Skeleton height={50} />
      </StyledSubTitle>
    )}
  </StyledChallengeHeader>
);

export default ChallengeHeader;

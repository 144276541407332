import Brand from "../pages/Brand";
// import Journey from "../pages/Journey";
import Category from "../pages/Category";
import Challenge from "../pages/Challenge";

export const ROUTE_INDEX = "/";
export const ROUTE_CATEGORY = `${ROUTE_INDEX}category/:category`;
export const ROUTE_CATEGORY_CHALLENGE = `${ROUTE_CATEGORY}/challenge/:challenge`;
export const ROUTE_CHALLENGE = `${ROUTE_INDEX}challenge/:challenge`;

// export const ROUTE_BRAND = `${ROUTE_INDEX}:world`;
// export const ROUTE_JOURNEY = `${ROUTE_BRAND}/journey/:challenge`;
// export const ROUTE_CHALLENGE = `${ROUTE_JOURNEY}/challenge/:challenge`;
// export const ROUTE_CHALLENGE = `${ROUTE_BRAND}/challenge/:challenge`;

const routes = [
  {
    path: ROUTE_INDEX,
    exact: true,
    component: Brand
  },
  {
    path: ROUTE_CATEGORY,
    exact: true,
    component: Category
  },
  {
    path: ROUTE_CHALLENGE,
    component: Challenge,
    exact: true
  }
];

export default routes;

import React from "react";
import styled from "styled-components";

const StyledContent = styled.div`
  padding: 50px 60px;

  padding-top: ${props => props.paddingTop && props.paddingTop};
  padding-bottom: ${props => props.paddingBottom && props.paddingBottom};

  @media (max-width: 575.98px) {
    padding: 50px 15px;
    padding-top: ${props => props.paddingTop && props.paddingTop};
    padding-bottom: ${props => props.paddingBottom && props.paddingBottom};
  }
`;

const Content = ({ children, ...rest }) => (
  <StyledContent {...rest}>{children}</StyledContent>
);

export default Content;

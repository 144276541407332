import React from "react";
import styled from "styled-components";
import { withRouter } from "react-router-dom";

import { Transition } from "react-transition-group";

const StyledLink = styled.a`
  cursor: pointer;

  transition: 0.2s;
  /* Hidden init state */
  transform: scale(1);

  &.exit,
  &.exited {
    /* Animate out state */
    transform: scale(0.95);
  }
`;

class SourceAwareLink extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      animate: true
    };

    this.timeout = null;
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
    this.setState({
      animate: false
    });
  }

  onClick = (to, search = "") => {
    this.setState({
      animate: false
    });
    this.timeout = setTimeout(() => {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.props.history.push(to + search);
    }, 300);
  };

  render() {
    const { to, external, children, location, ...rest } = this.props;

    return !!location.search === false ? (
      <Transition mountOnEnter timeout={100} in={this.state.animate}>
        {state => (
          <StyledLink
            onClick={e => this.onClick(to)}
            // href={to}
            className={`link ${rest.className} ${state}`}
            target={external && "_blank"}
          >
            {children}
          </StyledLink>
        )}
      </Transition>
    ) : (
      <Transition mountOnEnter timeout={100} in={this.state.animate}>
        {state => (
          <StyledLink
            onClick={e => this.onClick(to, location.search)}
            className={`link ${rest.className} ${state}`}
            // to={{ pathname: to, search: location.search }}
            target={external && "_blank"}
          >
            {children}
          </StyledLink>
        )}
      </Transition>
    );
  }
}

export default withRouter(SourceAwareLink);
